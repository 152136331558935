import {
  ProductCategoryViewModel,
  ProductViewModel,
  ProcessingTypeViewModel,
  PolymeCoverTypesViewModel,
  PriceViewModel,
  PricingType,
  MasterDataViewModel,
  OrderDetailViewModel,
  CatalogueOrderMaterial,
  CatalogueSizeOption,
  ProcessingSettingModel,
  SuppliesViewModel,
  BaseValueModelOfInteger,
  SuppliesCategoryViewModel,
  CreateExpensesDetailInput,
  ICreateExpensesDetailInput,
  OrderDetailStatus,
  FileParameter
} from '../../../api/api';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import BCardCode from '@core/components/b-card-code/BCardCode.vue';
import {
  BAvatar,
  BPagination,
  BFormGroup,
  BFormRadio,
  BFormRadioGroup,
  BFormInput,
  BFormSelect,
  BRow,
  BCol,
  BDropdown,
  BDropdownItem,
  BCard,
  BCardHeader,
  BCardBody,
  BButton,
  BForm,
  BCardText,
  BImg,
  BBadge,
  BFormTextarea,
  BFormFile,
  BLink,
  BFormSelectOption,
  BFormCheckbox,
  BModal,
  BSpinner,
  BOverlay,
  BIcon
} from 'bootstrap-vue';
import { quillEditor } from 'vue-quill-editor';
import CustomLabel from '@/@core/components/labels/CustomLabel.vue';
import { CreateOrderDetailInput, CreateOrderInput } from '@/api/api';
import { convertCurrencyToNumber, formatDate } from '@/utility/utils';
import ApiClientFactory from '@/api/apiClientFactory';
import { formatCurrency } from '@/utility/utils';
import { ModelSelect } from 'vue-search-select';
import 'vue-search-select/dist/VueSearchSelect.css';
import ExpensesDetailTable from './ExpensesDetailTable.vue';
import vSelect from 'vue-select';
import { mapActions, mapState } from 'vuex';
import {
  OrderState,
  ORDER_STATE_NAMESPACE
} from '@/store/order/order.module-types';
import {
  SettingAction,
  SettingState,
  SETTING_STATE_NAMESPACE
} from '@/store/setting/setting.module-types';
import { ValidationProvider } from 'vee-validate';
import {
  SUPPLIES_STATE_NAMESPACE,
  SuppliesAction,
  SuppliesState
} from '@/store/supplies/supplies.module-types';
import {
  SUPPLIES_CATEGORY_STATE_NAMESPACE,
  SuppliesCategoryAction,
  SuppliesCategoryState
} from '@/store/suppliesCategory/suppliesCategory.module-types';
import axios from 'axios';
import VueGallerySlideshow from 'vue-gallery-slideshow';

@Component({
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BCardCode,
    BAvatar,
    BPagination,
    BFormGroup,
    BFormRadioGroup,
    BFormInput,
    BFormSelect,
    BRow,
    BCol,
    BDropdown,
    BDropdownItem,
    BButton,
    BForm,
    BCardText,
    BImg,
    BBadge,
    BFormTextarea,
    BFormFile,
    BLink,
    quillEditor,
    CustomLabel,
    BFormSelectOption,
    BFormRadio,
    BFormCheckbox,
    BSpinner,
    ModelSelect,
    ExpensesDetailTable,
    vSelect,
    BOverlay,
    BIcon,
    VueGallerySlideshow
  },
  computed: {
    ...mapState(SUPPLIES_STATE_NAMESPACE, [
      SuppliesState.suppliesList,
      SuppliesState.suppliesNames
    ]),
    ...mapState(SUPPLIES_CATEGORY_STATE_NAMESPACE, [
      SuppliesCategoryState.suppliesCategories
    ]),
    ...mapState(SETTING_STATE_NAMESPACE, [SettingState.masterData])
  },
  methods: {
    ...mapActions(SUPPLIES_CATEGORY_STATE_NAMESPACE, [
      SuppliesCategoryAction.fetchSuppliesCategories
    ]),
    ...mapActions(SUPPLIES_STATE_NAMESPACE, [
      SuppliesAction.loadSupplies,
      SuppliesAction.fetchSuppliesNames,
      SuppliesAction.fetchSuppliesList
    ]),
    ...mapActions(SETTING_STATE_NAMESPACE, [SettingAction.fetchMasterData])
  }
})
export default class ExpensesDetailForm extends Vue {
  @Prop({ type: Object, required: true })
  expensesInput!: any;
  @Prop({ type: Boolean, required: true })
  isLoading!: boolean;
  @Prop({ type: Array, required: true })
  existingImgs!: string[];

  $refs!: {
    orderForm: InstanceType<typeof ValidationProvider>;
    addServiceFormRules: InstanceType<typeof ValidationProvider>;
    modal: InstanceType<typeof BModal>;
    tableRef: InstanceType<typeof ExpensesDetailTable>;
    pricePerUnitRef: InstanceType<typeof BFormInput>;
    addImageInput: InstanceType<typeof BFormFile>;
  };
  //orderInput: CreateOrderInput = this.getDefaultInput();

  pricingType: PricingType | undefined = undefined;
  standeeNote = '';
  vgsIndex = null;

  VUE_APP_API_BASE_HOST = process.env.VUE_APP_API_BASE_HOST;

  //Vuex
  suppliesList!: SuppliesViewModel[];
  suppliesNames!: BaseValueModelOfInteger[];
  loadSupplies!: () => Promise<void>;
  fetchSuppliesList!: () => Promise<void>;
  fetchSuppliesNames!: () => Promise<void>;
  suppliesCategories!: SuppliesCategoryViewModel[];
  fetchSuppliesCategories!: () => Promise<void>;
  masterData!: MasterDataViewModel;
  fetchMasterData!: () => Promise<void>;

  formatDate = formatDate;

  processingTypes: ProcessingTypeViewModel[] = [];
  processingTypes2: ProcessingTypeViewModel[] = [];
  polymeCoverTypes: PolymeCoverTypesViewModel[] = [];
  suppliesFiltered: SuppliesViewModel[] = [];
  productPrices: PriceViewModel[] = [];
  priceReferences: OrderDetailViewModel[] = [];
  catalogueProductItems: CatalogueOrderMaterial[] = [];
  processingSettings: ProcessingSettingModel[] = [];

  defaultServiceInputValue: CreateExpensesDetailInput = new CreateExpensesDetailInput(
    {
      suppliesId: 0,
      quantity: 0,
      width: 0,
      height: 0,
      pricePerUnit: 0,
      note: '',
      area: 0,
      categoryId: 0,
      depositAmount: 0,
      includeVAT: false,
      totalPrice: 0,
      vatAmount: 0,
      couponCode: '',
      sample: undefined,
      isUpdateImages: false,
      processingSettings: [],
      status: OrderDetailStatus.Ordered,
      indexImgId: 0
    }
  );
  expensesDetailInput: CreateExpensesDetailInput = {
    ...this.defaultServiceInputValue
  } as CreateExpensesDetailInput;
  selectedServiceToEditIndex?: number = undefined;

  selectedCategoryId = 0;
  searchSuppliesSelected: number | null = null;
  usingReferncePrice = false;

  selectedProcessingSetting: ProcessingSettingModel[] = [];
  selectedProcessingItemIds: any = [];

  files: any = [];
  fileImages: any = [];

  formatCurrency = formatCurrency;
  URL = URL;

  get getVGSImages() {
    if (this.fileImages?.length > 0) {
      return this.fileImages.map((s) => URL.createObjectURL(s));
    }
    return [];
  }

  mounted() {
    //this.isLoading = true;
    Promise.all([
      this.fetchSuppliesCategories(),
      this.fetchSuppliesNames(),
      this.fetchMasterData(),
      this.fetchSuppliesList()
    ])
      .catch((err) => {
        this.$bvToast.toast('Tải thông tin thất bại', {
          title: this.$t('SuppliesOrderToastTitle').toString(),
          toaster: 'b-toaster-bottom-right',
          variant: 'danger'
        });
      })
      .finally(() => {
        //this.isLoading = false;
      });
  }

  serviceFormSubmit(): void {
    this.$refs.addServiceFormRules.validate().then((success) => {
      if (!success) {
        return;
      }

      if (this.expensesDetailInput.quantity > 10000000) {
        this.$bvToast.toast('Số lượng vượt quá giới hạn 10 000 000', {
          title: this.$t('SuppliesOrderToastTitle').toString(),
          toaster: 'b-toaster-bottom-right',
          variant: 'danger'
        });
        return;
      }

      const updateItem = this.prepareExpensesDetail();

      if (this.expensesDetailInput.suppliesId && success) {
        if (this.selectedServiceToEditIndex !== undefined) {
          this.expensesInput.createExpensesDetails?.splice(
            this.selectedServiceToEditIndex,
            1
          );
          this.expensesInput.createExpensesDetails?.splice(
            this.selectedServiceToEditIndex,
            0,
            updateItem
          );
        } else {
          this.expensesInput.createExpensesDetails?.push(
            this.prepareExpensesDetail()
          );
          this.selectedServiceToEditIndex = undefined;
        }

        this.expensesDetailInput = {
          ...this.defaultServiceInputValue,
          suppliesId: this.expensesDetailInput.suppliesId,
          categoryId: this.expensesDetailInput.categoryId,
          pricePerUnit: this.expensesDetailInput.pricePerUnit
        } as CreateExpensesDetailInput;
        this.selectedServiceToEditIndex = undefined;
        this.selectedCategoryId = 0;
        this.standeeNote = '';

        this.fileImages = [];
        this.files = [];
      }
    });
  }

  prepareExpensesDetail(): CreateExpensesDetailInput {
    const processingSettingModelSelectedKeys = Object.keys(
      this.selectedProcessingItemIds
    );
    if (
      this.selectedProcessingItemIds &&
      processingSettingModelSelectedKeys.length > 0
    ) {
      const processingSettingModelSelecteds = processingSettingModelSelectedKeys.map(
        (processingId) => {
          const processing = this.processingSettings.find(
            (s) => s.id == processingId
          );

          const processingItem = processing?.processingSettingModelItems?.find(
            (s) => s.id == this.selectedProcessingItemIds[processingId]
          );

          return {
            id: processing?.id,
            name: processing?.name,
            processingSettingModelItems: [processingItem]
          } as ProcessingSettingModel;
        }
      );

      //this.selectedProcessingItemIds = [];
      this.expensesDetailInput.processingSettings = processingSettingModelSelecteds;
    }

    const images: FileParameter[] = this.fileImages.map((img) => {
      return {
        data: img,
        fileName: (img as any).name
      };
    });

    return {
      ...this.expensesDetailInput,
      quantity: convertCurrencyToNumber(this.expensesDetailInput.quantity),
      vatAmount:
        convertCurrencyToNumber(this.expensesDetailInput.totalPrice) * 0.08,
      pricePerUnit: convertCurrencyToNumber(
        this.expensesDetailInput.pricePerUnit
      ),
      totalPrice: convertCurrencyToNumber(this.expensesDetailInput.totalPrice),
      imageFiles: images
    } as CreateExpensesDetailInput;
  }

  async onSearchChange(data) {
    this.selectedProcessingItemIds = [];
    const selectesSupplies = this.suppliesList.find((s) => s.id === data);
    this.expensesDetailInput.categoryId =
      selectesSupplies?.suppliesCategoryId ?? 0;

    this.suppliesFiltered = this.suppliesList.filter(
      (val) => val.suppliesCategoryId === this.expensesDetailInput.categoryId
    );
    this.expensesDetailInput.suppliesId = selectesSupplies?.id ?? 0;
    this.searchSuppliesSelected = null;

    // this.orderDetailInput.pricePerUnit = 0;
    this.expensesDetailInput.totalPrice = 0;
  }

  onSuppliesCategoryChange() {
    this.suppliesFiltered = this.suppliesList
      .filter(
        (val) => val.suppliesCategoryId === this.expensesDetailInput.categoryId
      )
      .sort((a, b) => (a?.orderNumber ?? 0) - (b?.orderNumber ?? 0));
    this.expensesDetailInput.suppliesId = 0;
    this.searchSuppliesSelected = null;
    this.processingTypes = [];
    this.processingTypes2 = [];
    this.polymeCoverTypes = [];
    this.selectedProcessingItemIds = [];
  }

  onWidthHeightChange() {
    const area =
      this.expensesDetailInput.width *
      this.expensesDetailInput.height *
      this.expensesDetailInput.quantity;
    this.expensesDetailInput.area = parseFloat(area.toFixed(10));
  }

  onPriceChange(ignoreDefaultPrice) {
    this.onWidthHeightChange();

    const isPricingByQuantity =
      (this.pricingType && this.pricingType === PricingType.Quantity) ||
      (this.expensesDetailInput.quantity !== 0 &&
        this.expensesDetailInput.width === 0 &&
        this.expensesDetailInput.height === 0);
    if (isPricingByQuantity) {
      this.expensesDetailInput.totalPrice =
        convertCurrencyToNumber(this.expensesDetailInput.quantity) *
        convertCurrencyToNumber(this.expensesDetailInput.pricePerUnit);
      return;
    }

    if (this.pricingType && this.pricingType === PricingType.Sticker) {
      this.expensesDetailInput.totalPrice =
        convertCurrencyToNumber(this.expensesDetailInput.area) *
        convertCurrencyToNumber(this.expensesDetailInput.pricePerUnit);
      return;
    }

    if (this.pricingType && this.pricingType === PricingType.Size) {
      this.expensesDetailInput.totalPrice =
        this.expensesDetailInput.area *
        convertCurrencyToNumber(this.expensesDetailInput.pricePerUnit);
      return;
    }

    // const area = this.orderDetailInput.width * this.orderDetailInput.height;
    // this.orderDetailInput.area = parseFloat(area.toFixed(10));
    this.expensesDetailInput.totalPrice =
      (this.expensesDetailInput.area === 0
        ? 1
        : this.expensesDetailInput.area) *
      convertCurrencyToNumber(this.expensesDetailInput.pricePerUnit);
  }

  getPricePerUnit() {
    let finalPrice = 0;
    if (
      this.pricingType &&
      this.pricingType === PricingType.Quantity &&
      this.productPrices.length > 0
    ) {
      const priceSorted = this.productPrices[0].value!.sort(
        (a, b) => b.minQuantity - a.minQuantity
      );
      const price = priceSorted!.find(
        (s) => this.expensesDetailInput.quantity >= s.minQuantity
      );
      finalPrice =
        (!price ? priceSorted[priceSorted?.length - 1].price : price.price) ??
        0;
      return finalPrice;
    }

    if (
      this.pricingType &&
      this.pricingType === PricingType.Size &&
      this.productPrices.length > 0
    ) {
      const ereaInputted =
        this.expensesDetailInput.width * this.expensesDetailInput.height;

      const priceSorted = this.productPrices[0]
        .value!.filter((s) => s.area === ereaInputted)
        .sort((a, b) => b.minQuantity - a.minQuantity);

      const price = priceSorted!.find(
        (s) => this.expensesDetailInput.quantity >= s.minQuantity
      );
      finalPrice =
        (!price ? priceSorted[priceSorted?.length - 1].price : price.price) ??
        0;
      return finalPrice;
    }

    return finalPrice;
  }

  async onEditExpensesDetail(data) {
    this.selectedServiceToEditIndex = data.index;
    this.expensesDetailInput = {
      ...data.item
    } as CreateExpensesDetailInput;

    this.suppliesFiltered = this.suppliesList.filter(
      (val) => val.suppliesCategoryId === this.expensesDetailInput.categoryId
    );
    if (
      this.existingImgs &&
      this.existingImgs.length > 0 &&
      this.expensesDetailInput.imageFiles?.findIndex(
        (s) => typeof s === 'string' || s instanceof String
      ) !== -1
    ) {
      this.fileImages = [];
      for (let index = 0; index < this.existingImgs.length; index++) {
        const element = this.existingImgs[index];
        if (this.expensesDetailInput.imageFiles?.indexOf(element) !== -1) {
          const fileSrc = `${this.VUE_APP_API_BASE_HOST}/${element}`;

          const imgUrl = await this.createFileFromUrl(fileSrc);
          this.fileImages.push(imgUrl);
        }
      }
    } else {
      this.fileImages = this.expensesDetailInput.imageFiles?.map((s) => s.data);
    }
  }

  onSelectProcessingSettingModel(event, processingSettingId) {
    delete this.selectedProcessingItemIds[processingSettingId];
    if (event) {
      this.$set(this.selectedProcessingItemIds, processingSettingId, event);
    }
  }

  onSelectControlClose() {
    this.$refs.pricePerUnitRef.focus();
  }

  onReset() {
    this.selectedServiceToEditIndex = undefined;
    this.expensesDetailInput = {
      ...this.defaultServiceInputValue
    } as CreateExpensesDetailInput;
  }

  onImageInputClick() {
    const control = this.$refs.addImageInput.$el.childNodes[0] as any;
    control.click();
    this.expensesDetailInput.isUpdateImages =
      this.expensesDetailInput.id !== undefined;
  }

  onAddImageInput(event) {
    // if (event.target.files && event.target.files.length > 0) {
    //   //this.input.imageFiles.push(event.target.files[0]);
    //   this.fileImages.push(event.target.files);
    // }
    this.fileImages = [...this.fileImages, ...this.files];
  }

  removeImage(index) {
    this.fileImages.splice(index, 1);
    this.expensesDetailInput.isUpdateImages =
      this.expensesDetailInput.id !== undefined;
  }

  async createFileFromUrl(url: string) {
    const fileName = url.slice(url.lastIndexOf('/') + 1);

    const response = await axios.get(url, {
      responseType: 'arraybuffer'
    });
    const mimeType = response.headers['content-type'];
    return new File([response.data], fileName, { type: mimeType });
  }
}
