





































import ApiClientFactory from '@/api/apiClientFactory';
import { Vue, Component, Prop } from 'vue-property-decorator';
import {
  BModal,
  BFormInput,
  BRow,
  BCol,
  BContainer,
  BFormGroup,
  BForm,
  BButton,
  BFormTextarea
} from 'bootstrap-vue';
import {
  ValidationProvider,
  ValidationObserver
} from 'vee-validate/dist/vee-validate.full';
import {
  DEFAULT_ORDER_PAGE_SIZE,
  DEFAULT_PAGE_SIZE
} from '@/utility/pagination/pagination';
import { DropdownOption } from '@/utility/dropdowns/dropdownOptions';
import { listVueMixin } from '@/utility/pagination/list-vue.mixin';
import { expensesListDispatcher } from '../store/expenses-list.module';
import { MODAL_IDS } from '@/utility/constants';

@Component({
  components: {
    BModal,
    BFormInput,
    BContainer,
    BRow,
    BCol,
    BFormGroup,
    BForm,
    BButton,
    BFormTextarea,
    ValidationProvider,
    ValidationObserver
  },
  mixins: [
    listVueMixin({
      initialInput: {
        itemsPerPage: DEFAULT_ORDER_PAGE_SIZE,
        searchText: '',
        filterStatus: [] as DropdownOption[],
        filterPaymentStatus: [] as DropdownOption[],
        filterEmployee: ''
      },
      dispatcher: expensesListDispatcher
    })
  ]
})
export default class DepositInputModal extends Vue {
  depositAmount = 0;

  MODAL__IDS = MODAL_IDS;
  expensesListDispatcher = expensesListDispatcher;

  rejectPromise: any;
  resolvePromise: any;

  show() {
    return new Promise((resolve, reject) => {
      this.$bvModal.show(MODAL_IDS.ORDER_DEPOSIT_AMOUNT_MODAL);

      this.resolvePromise = resolve;
      this.rejectPromise = reject;
    });
  }

  onSubmitDeposit() {
    if (this.depositAmount < 0) {
      return;
    }

    this.$emit('update:depositAmount', this.depositAmount);
  }
}
