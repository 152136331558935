import {
  CatalogueOrderMaterial,
  CatalogueSizeOption,
  CustomerViewModel,
  FileParameter,
  OrderDetailViewModel,
  SelectModelOfString,
  VendorModel
} from '../../../api/api';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import BCardCode from '@core/components/b-card-code/BCardCode.vue';
import {
  BAvatar,
  BPagination,
  BFormGroup,
  BFormRadio,
  BFormRadioGroup,
  BFormInput,
  BFormSelect,
  BRow,
  BCol,
  BDropdown,
  BDropdownItem,
  BCard,
  BCardHeader,
  BCardBody,
  BButton,
  BForm,
  BCardText,
  BImg,
  BBadge,
  BFormTextarea,
  BFormFile,
  BLink,
  BFormSelectOption,
  BFormCheckbox,
  BModal,
  BSpinner,
  BOverlay,
  BFormDatepicker
} from 'bootstrap-vue';
import { quillEditor } from 'vue-quill-editor';
import CustomLabel from '@/@core/components/labels/CustomLabel.vue';
import {
  CreateExpensesDetailInput,
  CustomerLevel,
  Gender,
  OrderDeliveryType,
  OrderFormularType,
  PriceListViewModel
} from '@/api/api';
import { DropdownOption } from '@/utility/dropdowns/dropdownOptions';
import {
  convertCurrencyToNumber,
  enumToDropdownOptions,
  formatDate
} from '@/utility/utils';
import ApiClientFactory from '@/api/apiClientFactory';
import { PriceGetter, PriceNamespace } from '@/store/price/price.module-types';
import { formatCurrency } from '@/utility/utils';
import { ModelSelect } from 'vue-search-select';
import 'vue-search-select/dist/VueSearchSelect.css';
import ExpensesDetailTable from './ExpensesDetailTable.vue';
import vSelect from 'vue-select';
import { mapActions, mapState } from 'vuex';
import {
  OrderState,
  ORDER_STATE_NAMESPACE
} from '@/store/order/order.module-types';
import {
  CustomerAction,
  CustomerState,
  CUSTOMER_STATE_NAMESPACE
} from '@/store/customer/customer.module-types';
import {
  SettingAction,
  SettingState,
  SETTING_STATE_NAMESPACE
} from '@/store/setting/setting.module-types';
import { ValidationProvider } from 'vee-validate';
import OrderDetailForm from './ExpensesDetailForm.vue';
import {
  EMPLOYEE_STATE_NAMESPACE,
  EmployeeAction,
  EmployeeState
} from '@/store/employee/employee.module-types';
import {
  VENDOR_STATE_NAMESPACE,
  VendorAction,
  VendorState
} from '@/store/vendor/vendor.module-types';
import ExpensesDetailForm from './ExpensesDetailForm.vue';

@Component({
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BCardCode,
    BAvatar,
    BPagination,
    BFormGroup,
    BFormRadioGroup,
    BFormInput,
    BFormSelect,
    BRow,
    BCol,
    BDropdown,
    BDropdownItem,
    BButton,
    BForm,
    BCardText,
    BImg,
    BBadge,
    BFormTextarea,
    BFormFile,
    BLink,
    quillEditor,
    CustomLabel,
    BFormSelectOption,
    BFormRadio,
    BFormCheckbox,
    BSpinner,
    ModelSelect,
    ExpensesDetailTable,
    vSelect,
    BOverlay,
    ExpensesDetailForm,
    BFormDatepicker
  },
  computed: {
    ...mapState(ORDER_STATE_NAMESPACE, [OrderState.orderMaterial]),
    ...mapState(VENDOR_STATE_NAMESPACE, [VendorState.vendors]),
    ...mapState(SETTING_STATE_NAMESPACE, [SettingState.masterData]),
    ...mapState(EMPLOYEE_STATE_NAMESPACE, [EmployeeState.saleEmployees])
  },
  methods: {
    ...mapActions(CUSTOMER_STATE_NAMESPACE, [CustomerAction.fetchCustomers]),
    ...mapActions(VENDOR_STATE_NAMESPACE, [VendorAction.fetchVendors]),
    ...mapActions(EMPLOYEE_STATE_NAMESPACE, [EmployeeAction.fetchSaleEmployees])
  }
})
export default class ExpensesForm extends Vue {
  @PriceNamespace.Getter(PriceGetter.priceList)
  priceList!: PriceListViewModel[];

  @Prop({ type: Number, required: true })
  expensesId!: number;

  isEditting = false;
  isLoading = false;

  $refs!: {
    orderForm: InstanceType<typeof ValidationProvider>;
    expensesDetailForm: InstanceType<any>;
    modal: InstanceType<typeof BModal>;
    tableRef: InstanceType<typeof ExpensesDetailTable>;
    pricePerUnitRef: InstanceType<typeof BFormInput>;
  };
  orderDeliveryTypeOptions: DropdownOption[] = enumToDropdownOptions(
    OrderDeliveryType
  );
  customerLevelOptions: DropdownOption[] = enumToDropdownOptions(CustomerLevel);
  expensesInput = this.getDefaultInput();
  isSubmitting = false;

  //Vuex
  vendors!: VendorModel[];
  fetchVendors!: () => Promise<void>;
  // masterData!: MasterDataViewModel;
  // fetchMasterData!: () => Promise<void>;

  saleEmployees!: SelectModelOfString[];
  fetchSaleEmployees!: () => Promise<void>;

  formatDate = formatDate;

  priceReferences: OrderDetailViewModel[] = [];

  defaultServiceInputValue: CreateExpensesDetailInput = new CreateExpensesDetailInput(
    {
      area: 0,
      categoryId: 0,
      depositAmount: 0,
      height: 0,
      includeVAT: false,
      isUpdateImages: false,
      pricePerUnit: 0,
      quantity: 0,
      totalPrice: 0,
      width: 0
    } as CreateExpensesDetailInput
  );
  selectedServiceToEditIndex?: number = undefined;
  OrderFormularType = OrderFormularType;

  employeeOptions: DropdownOption[] = [];

  get vendorOptions() {
    if (this.vendors.length === 0) {
      return [{ id: '', name: '' } as VendorModel];
    }
    return this.vendors;
  }

  get existingImgs() {
    if (this.expensesId && this.expensesId > 0) {
      return this.expensesInput.createExpensesDetails?.flatMap(
        (s) => s.imageFiles
      );
    }
    return [];
  }

  mounted() {
    //this.orderId = parseInt(this.$route.params.id);
    if (!this.vendors || this.vendors.length <= 0) {
      this.fetchVendors();
    }
  }

  @Watch('expensesId', { immediate: true })
  expensesIdUpdateHandle() {
    if (this.expensesId && this.expensesId != 0) {
      this.isEditting = true;
      this.loadExpenses();
    }
  }

  @Watch('saleEmployees', { immediate: true })
  loadSaleEmployees() {
    this.employeeOptions = this.saleEmployees?.map(
      (x) =>
        ({
          value: x.id,
          text: x.name
        } as DropdownOption)
    );
    if (
      (!this.expensesInput.employeeId || this.expensesInput.employeeId == '') &&
      this.employeeOptions?.length > 0
    ) {
      this.expensesInput.employeeId = this.employeeOptions[0].value;
    }
  }

  formatCurrency = formatCurrency;

  loadExpenses() {
    this.isLoading = true;
    const client = new ApiClientFactory().expensesClient();
    client.get(this.expensesId).then((val) => {
      this.expensesInput.discountAmount = val.couponAmount;
      this.expensesInput.vendorAddress = val.vendorAddress ?? '';
      this.expensesInput.vendorId = val.vendorId;
      this.expensesInput.vendorName = val.vendorName ?? '';
      this.expensesInput.vendorEmail = val.vendorEmail ?? '';
      this.expensesInput.vendorPhoneNumber = val.vendorPhone ?? '';
      this.expensesInput.importDate = new Date(val.importDate);
      this.expensesInput.depositAmount = val.depositAmount;
      this.expensesInput.employeeId = val.employeeId ?? '';
      this.expensesInput.shippingFee = val.shippingFee;
      this.expensesInput.vatAmount = val.vatAmount;

      if (val.expensesDetails && val.expensesDetails.length > 0) {
        val.expensesDetails.forEach((s) => {
          const expensesDetail = {
            id: s.id,
            area: s.area,
            height: s.height,
            pricePerUnit: s.pricePerUnit,
            quantity: s.quantity,
            totalPrice: s.total,
            suppliesId: s.suppliesId,
            categoryId: s.categoryId,
            width: s.width,
            note: s.note,
            status: s.status,
            depositAmount: 0,
            includeVAT: false,
            indexImgId: 0,
            isUpdateImages: false,
            imageFiles: s.imagePaths
          } as CreateExpensesDetailInput;
          this.expensesInput.createExpensesDetails?.push(expensesDetail);
        });
      }
    });
    this.isLoading = false;
  }

  getDefaultInput() {
    return {
      vendorId: '',
      vendorName: '',
      vendorPhoneNumber: '',
      vendorAddress: '',
      vendorEmail: '',
      importDate: new Date(),
      createExpensesDetails: new Array<CreateExpensesDetailInput>(),
      vatAmount: 0,
      discountAmount: 0,
      shippingFee: 0,
      depositAmount: 0,
      employeeId: ''
    };
  }

  create() {
    this.isLoading = true;

    this.expensesInput.createExpensesDetails?.forEach((s) => {
      s.quantity = convertCurrencyToNumber(s.quantity);
      s.pricePerUnit = convertCurrencyToNumber(s.pricePerUnit);
      s.totalPrice = convertCurrencyToNumber(s.totalPrice);
      s.vatAmount = convertCurrencyToNumber(s.vatAmount);
    });

    const imgs: FileParameter[] = this.prepareImages();

    const expensesDetails = this.expensesInput.createExpensesDetails?.map(
      (s) => {
        return {
          ...s,
          imageFiles: []
        };
      }
    );

    const client = new ApiClientFactory().expensesClient();
    client
      .create(
        this.expensesInput.vendorId,
        this.expensesInput.vendorName,
        this.expensesInput.vendorPhoneNumber,
        this.expensesInput.vendorAddress,
        this.expensesInput.vendorEmail,
        convertCurrencyToNumber(this.expensesInput.shippingFee),
        this.expensesInput.importDate,
        null,
        JSON.stringify(expensesDetails),
        this.expensesInput.employeeId,
        convertCurrencyToNumber(this.expensesInput.discountAmount),
        convertCurrencyToNumber(this.expensesInput.depositAmount),
        convertCurrencyToNumber(this.expensesInput.vatAmount),
        '0',
        [...imgs] as FileParameter[]
      )
      .then(() => {
        this.$bvToast.toast('Thêm đơn hàng thành công', {
          title: 'Đơn hàng',
          toaster: 'b-toaster-bottom-right',
          variant: 'success'
        });

        this.onBackHandle();
      })
      .catch((err) => {
        this.$bvToast.toast('Lỗi Khi Lưu', {
          title: 'Đơn hàng',
          toaster: 'b-toaster-bottom-right',
          variant: 'danger'
        });
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  private prepareImages() {
    const imgs: FileParameter[] = [];
    for (
      let index = 0;
      index < (this.expensesInput.createExpensesDetails?.length ?? 0);
      index++
    ) {
      if (this.expensesInput.createExpensesDetails) {
        const element = this.expensesInput.createExpensesDetails[index];
        if (!this.isEditting || element.isUpdateImages || !element.id) {
          element.indexImgId = index;
          for (
            let imgIndex = 0;
            imgIndex < (element.imageFiles?.length ?? 0);
            imgIndex++
          ) {
            if (element.imageFiles) {
              const img = element.imageFiles[imgIndex] as FileParameter;
              img.fileName = `[${index}]_${img.fileName}`;
              imgs.push(img);
            }
          }
        }
      }
    }
    return imgs;
  }

  update() {
    this.isLoading = true;

    this.expensesInput.createExpensesDetails?.forEach((s) => {
      s.quantity = convertCurrencyToNumber(s.quantity);
      s.pricePerUnit = convertCurrencyToNumber(s.pricePerUnit);
      s.totalPrice = convertCurrencyToNumber(s.totalPrice);
      s.vatAmount = convertCurrencyToNumber(s.vatAmount);
    });

    const imgs: FileParameter[] = this.prepareImages();
    const expensesDetails = this.expensesInput.createExpensesDetails?.map(
      (s) => {
        return {
          ...s,
          indexImgId: s.indexImgId,
          imageFiles: []
        };
      }
    );
    const client = new ApiClientFactory().expensesClient();
    client
      .update(
        this.expensesId,
        this.expensesInput.vendorId,
        this.expensesInput.vendorName,
        this.expensesInput.vendorPhoneNumber,
        this.expensesInput.vendorAddress,
        this.expensesInput.vendorEmail,
        convertCurrencyToNumber(this.expensesInput.shippingFee),
        this.expensesInput.importDate,
        null,
        JSON.stringify(expensesDetails),
        this.expensesInput.employeeId,
        convertCurrencyToNumber(this.expensesInput.discountAmount),
        convertCurrencyToNumber(this.expensesInput.depositAmount),
        convertCurrencyToNumber(this.expensesInput.vatAmount),
        '0',
        [...imgs] as FileParameter[]
      )
      .then((result) => {
        if (result) {
          this.$bvToast.toast('Sửa đơn hàng thành công', {
            title: 'Đơn hàng',
            toaster: 'b-toaster-bottom-right',
            variant: 'success'
          });
          this.onBackHandle();
          return;
        }
        this.$bvToast.toast('Lỗi Khi Lưu', {
          title: 'Đơn hàng',
          toaster: 'b-toaster-bottom-right',
          variant: 'danger'
        });
      })
      .catch((err) => {
        this.$bvToast.toast('Lỗi Khi Lưu', {
          title: 'Đơn hàng',
          toaster: 'b-toaster-bottom-right',
          variant: 'danger'
        });
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  submit() {
    this.$refs.orderForm.validate().then((success) => {
      if (success) {
        if (this.isEditting === true) {
          this.update();
          return;
        }
        this.create();
      }
    });
  }

  onReset() {
    this.selectedServiceToEditIndex = undefined;
    this.$refs.expensesDetailForm.onReset();
  }

  onVendorChange(value) {
    if (value) {
      const vendor = this.vendors.filter(
        (s) => s.name?.toLowerCase() === value.toLowerCase()
      );
      if (vendor && vendor.length > 0) {
        this.expensesInput.vendorId = vendor[0].id;
        this.expensesInput.vendorPhoneNumber = vendor[0].phoneNumber ?? '';
        this.expensesInput.vendorEmail = vendor[0].email ?? '';
        this.expensesInput.vendorAddress = vendor[0].address ?? '';
        return;
      }

      this.expensesInput.vendorId = '';
      this.expensesInput.vendorPhoneNumber = '';
    }
  }

  onBackHandle() {
    this.expensesInput = this.getDefaultInput();
    this.onReset();
    this.isEditting = false;
    this.$emit('OnSubmitSuccess');
  }

  isAccessFromMobile() {
    const details = navigator.userAgent;

    /* Creating a regular expression
      containing some mobile devices keywords
      to search it in details string*/
    const regexp = /android|iphone|kindle|ipad/i;

    /* Using test() method to search regexp in details
      it returns boolean value*/
    console.log('isAccessMobile: ', regexp.test(details));
    return regexp.test(details);
  }
}
