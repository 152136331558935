var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{staticClass:"order-form mt-2 mx-1"},[_c('b-overlay',{attrs:{"id":"overlay-background","show":_vm.isLoading,"variant":"light","opacity":"0.8","blur":"1px","rounded":"lg"}},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.submit($event)}}},[_c('validation-observer',{ref:"orderForm"},[_c('b-row',[_c('b-col',{attrs:{"sm":"6"}},[_c('h4',[_c('strong',[_vm._v("Thông Tin Đơn Hàng")])])]),_c('b-col',{attrs:{"sm":"6"}},[_c('h4',[_c('strong',[_vm._v("Thông Tin Nhà Cung Cấp")])])])],1),_c('b-row',[_c('b-col',{attrs:{"sm":"6"}},[_c('b-row',{staticClass:"mt-0"},[_c('b-col',{attrs:{"sm":"6"}},[_c('h6',{staticClass:"text-primary"},[_vm._v("CHỌN NHÂN VIÊN")]),_c('b-form-group',[_c('validation-provider',{attrs:{"name":"employee","rules":"required","mode":"lazy"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"id":"employee","state":errors.length > 0 ? false : null},model:{value:(_vm.expensesInput.employeeId),callback:function ($$v) {_vm.$set(_vm.expensesInput, "employeeId", $$v)},expression:"expensesInput.employeeId"}},_vm._l((_vm.employeeOptions),function(item){return _c('b-form-select-option',{key:item.value,attrs:{"value":item.value}},[_vm._v(" "+_vm._s(_vm.$t(item.text))+" ")])}),1),(errors.length > 0)?_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()]}}])})],1)],1),_c('b-col',{attrs:{"sm":"6"}},[_c('h6',{staticClass:"text-primary required"},[_vm._v("NGÀY NHẬP HÀNG")]),_c('b-form-group',[_c('b-form-datepicker',{attrs:{"date-format-options":{
                      year: 'numeric',
                      month: 'numeric',
                      day: 'numeric'
                    },"value-as-date":true,"reset-button":true},model:{value:(_vm.expensesInput.importDate),callback:function ($$v) {_vm.$set(_vm.expensesInput, "importDate", $$v)},expression:"expensesInput.importDate"}})],1)],1)],1)],1),_c('b-col',{attrs:{"sm":"6"}},[_c('h6',{staticClass:"text-primary required text-uppercase"},[_vm._v(" "+_vm._s(_vm.$t('VendorName'))+" ")]),_c('b-form-group',[_c('validation-provider',{attrs:{"name":"VendorName","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
                    var validate = ref.validate;
return [_c('v-select',{attrs:{"taggable":"","id":"VendorName","label":"name","state":errors.length > 0 ? false : null,"options":_vm.vendorOptions,"reduce":function (vendor) { return vendor.name; },"value":_vm.expensesInput.vendorName},on:{"input":_vm.onVendorChange,"close":function($event){return validate()}},model:{value:(_vm.expensesInput.vendorName),callback:function ($$v) {_vm.$set(_vm.expensesInput, "vendorName", $$v)},expression:"expensesInput.vendorName"}}),(errors.length > 0)?_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"sm":"6"}}),_c('b-col',{attrs:{"sm":"6"}},[_c('b-row',[_c('b-col',{attrs:{"sm":"6"}},[_c('div',[_c('h6',{staticClass:"text-primary upercase required"},[_vm._v(" "+_vm._s(_vm.$t('Phone'))+" ")]),_c('b-form-group',[_c('validation-provider',{attrs:{"name":"phone","rules":"required|numeric","mode":"lazy"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"phone","state":errors.length > 0 ? false : null,"placeholder":_vm.$t('Phone')},model:{value:(_vm.expensesInput.vendorPhoneNumber),callback:function ($$v) {_vm.$set(_vm.expensesInput, "vendorPhoneNumber", $$v)},expression:"expensesInput.vendorPhoneNumber"}}),(errors.length > 0)?_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()]}}])})],1)],1),_c('div',{staticClass:"mt-1"},[_c('h6',{staticClass:"text-primary"},[_vm._v("EMAIL (không bắt buộc)")]),_c('b-form-group',[_c('validation-provider',{attrs:{"name":"email","rules":"email","mode":"lazy"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Nhập email...","state":errors.length > 0 ? false : null},model:{value:(_vm.expensesInput.vendorEmail),callback:function ($$v) {_vm.$set(_vm.expensesInput, "vendorEmail", $$v)},expression:"expensesInput.vendorEmail"}}),(errors.length > 0)?_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()]}}])})],1)],1)]),_c('b-col',{attrs:{"sm":"6"}},[_c('h6',{staticClass:"text-primary required"},[_vm._v("ĐỊA CHỈ")]),_c('b-form-group',[_c('div',[_c('validation-provider',{attrs:{"name":"address","rules":"required","mode":"lazy"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"customerAddress","placeholder":_vm.$t('Address1'),"required":true,"rows":"3","state":errors.length > 0 ? false : null},model:{value:(_vm.expensesInput.vendorAddress),callback:function ($$v) {_vm.$set(_vm.expensesInput, "vendorAddress", $$v)},expression:"expensesInput.vendorAddress"}}),(errors.length > 0)?_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()]}}])})],1)])],1)],1)],1)],1)],1)],1),_c('ExpensesDetailForm',{ref:"expensesDetailForm",attrs:{"expensesInput":_vm.expensesInput,"isLoading":_vm.isLoading,"existingImgs":_vm.existingImgs}}),_c('b-row',{staticClass:"d-flex justify-content-end mt-1"},[_c('b-card-text',{staticClass:"font-medium-5 mr-1"},[(_vm.isAccessFromMobile())?_c('b-button',{staticClass:"mx-1",attrs:{"variant":"primary","disabled":_vm.isSubmitting},on:{"click":_vm.serviceFormSubmit}},[_vm._v(" Thêm hàng hóa ")]):_vm._e(),_c('b-button',{attrs:{"type":"submit","variant":"primary","disabled":_vm.isSubmitting},on:{"click":_vm.submit}},[[_vm._v(" "+_vm._s(_vm.$t('Save'))+" ")]],2),_c('b-button',{staticClass:"mx-1",attrs:{"type":"reset","variant":"danger"},on:{"click":_vm.onReset}},[_vm._v(" "+_vm._s(_vm.$t('Resest'))+" ")]),_c('b-button',{attrs:{"variant":"outline-secondary"},on:{"click":_vm.onBackHandle}},[_vm._v(" "+_vm._s(_vm.$t('Back'))+" ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }