
















































































































































































































import {
  BaseValueModelOfInteger,
  CatalogueOrderMaterial,
  CatalogueSizeOption,
  CreateExpensesDetailInput,
  CreateOrderDetailInput,
  CreateOrderInput,
  MasterDataViewModel,
  ProductViewModel
} from '@/api/api';
import {
  SETTING_STATE_NAMESPACE,
  SettingState
} from '@/store/setting/setting.module-types';
import { DropdownOption } from '@/utility/dropdowns/dropdownOptions';
import { formatCurrency, convertCurrencyToNumber } from '@/utility/utils';
import {
  BTable,
  BTh,
  BTd,
  BTr,
  BTfoot,
  BThead,
  BTbody,
  BFormInput,
  BButton,
  BFormCheckbox,
  BRow,
  BCol
} from 'bootstrap-vue';
import Vue, { PropType } from 'vue';
import Component from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import { mapActions, mapState } from 'vuex';
import vSelect from 'vue-select';
import {
  SUPPLIES_STATE_NAMESPACE,
  SuppliesAction,
  SuppliesState
} from '@/store/supplies/supplies.module-types';

const ExpensesDetailTableProps = Vue.extend({
  props: {
    expenses: Object
  }
});
// Define the component in class-style
@Component({
  components: {
    BTable,
    BTh,
    BTd,
    BTr,
    BTfoot,
    BThead,
    BTbody,
    BFormInput,
    BButton,
    BFormCheckbox,
    BRow,
    BCol,
    vSelect
  },
  computed: {
    ...mapState(SUPPLIES_STATE_NAMESPACE, [SuppliesState.suppliesNames]),
    ...mapState(SETTING_STATE_NAMESPACE, [SettingState.masterData])
  },
  methods: {
    ...mapActions(SUPPLIES_STATE_NAMESPACE, [SuppliesAction.fetchSuppliesNames])
  }
})
export default class ExpensesDetailTable extends ExpensesDetailTableProps {
  //Vuex
  suppliesNames!: BaseValueModelOfInteger[];
  fetchSuppliesNames!: () => Promise<void>;
  masterData!: MasterDataViewModel;

  // Class properties will be component data
  fields = [
    {
      key: 'index',
      label: this.$t('index'),
      thClass: 'th-color',
      thStyle: { width: '1%' }
    },
    {
      key: 'suppliesId',
      label: this.$t('Supplies'),
      thClass: 'th-color',
      width: '20%'
    },
    {
      key: 'note',
      label: this.$t('Note'),
      thClass: 'th-color',
      thStyle: { width: '15%' }
    },
    {
      key: 'width',
      label: this.$t('Widthlbl'),
      thClass: 'th-color'
    },
    {
      key: 'height',
      label: this.$t('Heightlbl'),
      thClass: 'th-color'
    },
    {
      key: 'quantity',
      label: this.$t('Quantitylbl'),
      thClass: 'th-color'
    },
    {
      key: 'area',
      label: this.$t('TotalArealbl'),
      thClass: 'th-color'
    },
    // {
    //   key: 'totalArea',
    //   label: this.$t('TotalArealbl'),
    //   thClass: 'th-color'
    // },
    {
      key: 'pricePerUnit',
      label: this.$t('PricePerUnit'),
      thClass: 'th-color',
      thStyle: { width: '7%' }
    },
    {
      key: 'totalPrice',
      label: this.$t('Price'),
      thClass: 'th-color',
      thStyle: { width: '10%' }
    },
    {
      key: 'Actions',
      label: '',
      thClass: 'th-color',
      thStyle: { width: '5%' }
    }
  ];
  catalogueSizes: CatalogueSizeOption[] = [];
  vatOptions = [8, 10];

  get colSpanNumber(): number {
    return 7;
  }

  get vATAmount(): number {
    let vatAmount = 0;
    this.expenses?.createExpensesDetails?.forEach(
      (s) => (vatAmount += s.includeVAT === true ? s.vatAmount : 0)
    );

    return vatAmount;
  }

  isNewOrderDetailItem(data) {
    return !data.item.id || data.item.id == '';
  }

  @Watch('expenses', { deep: true })
  totalPrice(): number {
    let price = 0;
    this.expenses?.createExpensesDetails?.forEach(
      (s) =>
        (price += convertCurrencyToNumber(
          this.getFinalPrice({ item: { ...s } })
        ))
    );
    const vatAmount =
      (convertCurrencyToNumber(this.expenses.vatAmount) / 100) * price;
    const total =
      convertCurrencyToNumber(this.expenses.shippingFee) +
      vatAmount +
      price -
      convertCurrencyToNumber(this.expenses.discountAmount);
    return total < 0 ? 0 : total;
  }

  get remainAmount(): number {
    return (
      this.totalPrice() - convertCurrencyToNumber(this.expenses.depositAmount)
    );
  }

  get orderDetailItems(): CreateExpensesDetailInput[] {
    return this.expenses.createExpensesDetails ?? [];
  }

  // Methods will be component methods
  formatCurrency = formatCurrency;

  getSuppliesName(suppliesId) {
    return this.suppliesNames.find((s) => s.value === suppliesId)?.text ?? '';
  }

  getFinalPrice(data) {
    if (data) {
      return formatCurrency(data.item.totalPrice);
    }
  }

  onEdit(data) {
    this.$emit('onEditOrderDetail', data);
  }

  onDelete(data) {
    const removeIndexItem = data.index;
    this.expenses.createExpensesDetails?.splice(removeIndexItem, 1);
  }

  validate() {
    const inputValue = convertCurrencyToNumber(this.expenses.vatAmount);
    if (isNaN(inputValue) || inputValue < 0 || inputValue > 100) {
      this.expenses.vatAmount = 0;
    }
  }
}
