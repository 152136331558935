import { Vue, Component, Prop } from 'vue-property-decorator';
import BCardCode from '@core/components/b-card-code/BCardCode.vue';
import {
  BAvatar,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BRow,
  BCol,
  BDropdown,
  BDropdownItem,
  BCard,
  BCardHeader,
  BCardBody,
  BButton,
  BForm,
  BCardText,
  BImg,
  BBadge,
  BFormTextarea,
  BFormFile,
  BLink,
  BModal,
  BFormCheckbox
} from 'bootstrap-vue';
import {
  enumToDropdownOptions,
  formatCurrency,
  StringFormat
} from '@/utility/utils';
import {
  MasterDataViewModel,
  OrderDetailStatus,
  OrderDetailViewModel,
  OrderStatus,
  OrderViewModel
} from '@/api/api';
import { VueGoodTable } from 'vue-good-table';
import { DropdownOption } from '@/utility/dropdowns/dropdownOptions';
import ApiClientFactory from '@/api/apiClientFactory';
import { OrderGetter, OrderNamespace } from '@/store/order/order.module-types';
import { PrintType } from '@/views/print/orderDetailPrint/OrderDetailPrint';
import { MODAL_IDS, OrderDetailStatusChangeFlow } from '@/utility/constants';
import AuthStorageService from '@/utility/account/auth.storage.service';
import { mapActions, mapState } from 'vuex';
import {
  SETTING_STATE_NAMESPACE,
  SettingAction,
  SettingState
} from '@/store/setting/setting.module-types';

@Component({
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BCardCode,
    BAvatar,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BRow,
    BCol,
    BDropdown,
    BDropdownItem,
    BButton,
    BForm,
    BCardText,
    BImg,
    BBadge,
    BFormTextarea,
    BFormFile,
    BLink,
    VueGoodTable,
    BModal,
    BFormCheckbox
  },
  computed: {
    ...mapState(SETTING_STATE_NAMESPACE, [SettingState.masterData])
  },
  methods: {
    ...mapActions(SETTING_STATE_NAMESPACE, [SettingAction.fetchMasterData])
  }
})
export default class OrderItemDetailsModal extends Vue {
  // @Prop({ type: Array, required: true })
  // items!: OrderDetailViewModel[];

  // @Prop({ type: Number, required: true })
  // orderId!: number;

  // @Prop({ type: String, required: true })
  // customer!: string;

  masterData!: MasterDataViewModel;
  fetchMasterData!: () => Promise<void>;

  @OrderNamespace.Getter(OrderGetter.order)
  order!: OrderViewModel;

  orderStatusOptions: DropdownOption[] = enumToDropdownOptions(OrderStatus);
  orderDetailStatusOptions: DropdownOption[] = enumToDropdownOptions(
    OrderDetailStatus
  );

  hasFullPermission = AuthStorageService.isFullPermission();

  formatCurrency = formatCurrency;
  MODAL_IDS = MODAL_IDS;

  headers = [
    {
      field: 'serviceName',
      label: this.$t('ServiceName'),
      sortable: false,
      width: '30%'
    },
    {
      field: 'status',
      label: this.$t('Status'),
      sortable: false
    },
    // {
    //   field: 'employee',
    //   label: this.$t('Employee'),
    //   sortable: false
    // },
    {
      field: 'width',
      label: this.$t('Widthlbl'),
      sortable: false,
      width: '8%'
    },
    {
      field: 'height',
      label: this.$t('Heightlbl'),
      sortable: false,
      width: '8%'
    },
    // {
    //   field: 'area',
    //   label: this.$t('Arealbl'),
    //   sortable: false
    // },
    {
      field: 'quantity',
      label: this.$t('Quantity'),
      sortable: false,
      width: '8%'
    },
    // {
    //   field: 'totalArea',
    //   label: this.$t('TotalArealbl'),
    //   sortable: false
    // },
    // {
    //   field: 'pricePerUnit',
    //   label: this.$t('PricePerUnit'),
    //   sortable: false
    // },
    // {
    //   field: 'vatAmount',
    //   label: this.$t('Vatlbl'),
    //   sortable: false
    // },
    // {
    //   field: 'total',
    //   label: this.$t('TotalPrice'),
    //   sortable: false
    // },
    {
      field: 'note',
      label: this.$t('Note'),
      sortable: false,
      width: '30%'
    }
  ];

  get orderDetails() {
    return this.order?.orderDetails ?? [];
  }

  get customerName(): string {
    return this.order?.customerName ?? '';
  }

  mounted() {
    if (!this.masterData) {
      this.fetchMasterData();
    }
  }

  displayStatus(value) {
    const status = this.orderDetailStatusOptions.find(
      (x) => x.value === value.status
    );
    return status ? this.$t(status?.text as string) : '';
  }

  updateStatus(orderDetailId, status) {
    const client = new ApiClientFactory().orderClient();
    if (this.isOrderAbleToUpdate()) {
      client
        .updateOrderDetailStatus(orderDetailId, status)
        .then((response) => {
          if (response) {
            const index = this.order.orderDetails?.findIndex(
              (s) => s.id === orderDetailId
            );
            if (
              this.order.orderDetails &&
              index !== undefined &&
              index !== -1
            ) {
              this.order.orderDetails[index].status = status;
            }

            this.$bvToast.toast(
              StringFormat(
                this.$t('UpdateStatusSuccessfully').toString(),
                this.$t('Order').toString().toLowerCase()
              ),
              {
                title: this.$t('Order').toString(),
                toaster: 'b-toaster-bottom-right',
                variant: 'success'
              }
            );

            this.$emit('onUpdateStatus');
          } else {
            this.$bvToast.toast(
              StringFormat(
                'Chỉnh sửa trạng thái thất bại.',
                this.$t('Order').toString().toLowerCase()
              ),
              {
                title: this.$t('Order').toString(),
                toaster: 'b-toaster-bottom-right',
                variant: 'danger'
              }
            );
          }
        })
        .catch((err) => {
          this.$bvToast.toast(
            StringFormat(
              err.message,
              this.$t('Order').toString().toLowerCase()
            ),
            {
              title: this.$t('Order').toString(),
              toaster: 'b-toaster-bottom-right',
              variant: 'danger'
            }
          );
        });
    }
  }

  onPrint() {
    const newWindow = window.open(
      `/print-order/${
        this.order.id
      }?type=${PrintType.OrderProducing.toString()}`,
      '_blank',
      `width=1000,height=${window.innerHeight}`
    );
    if (newWindow === null) return;
  }

  isOrderAbleToUpdate() {
    const inValidToUpdate =
      this.order.status === OrderStatus.Delivered &&
      this.order.orderDetails?.findIndex(
        (s) => s.status !== OrderDetailStatus.Delivered
      ) === -1;
    if (inValidToUpdate && !this.hasFullPermission) {
      this.$bvToast.toast('Không thể chỉnh sửa đơn hàng đã hoàn thành', {
        title: this.$t('Order').toString(),
        toaster: 'b-toaster-bottom-right',
        variant: 'danger'
      });
    }

    return !inValidToUpdate || this.hasFullPermission;
  }

  // getServiceName(orderDetail: OrderDetailViewModel) {
  //   if (orderDetail.categoryId === this.masterData.catalogueCategoryId) {
  //     return `<div>${orderDetail.serviceName} <br>
  //             - ${orderDetail.catalogueProductName} <br>
  //             - ${orderDetail.polymeCoverTypeName} <br>
  //             - ${orderDetail.catalogueSizeName} <br>
  //             - ${orderDetail.totalPage} Trang
  //             </div>`;
  //   }

  //   return orderDetail.serviceName;
  // }

  getServiceName(orderDetail: OrderDetailViewModel) {
    const productName = orderDetail.serviceName;
    if (
      orderDetail.categoryId &&
      orderDetail.categoryId === this.masterData.catalogueCategoryId
    ) {
      return `<div><div class='row mx-0'><div class='col-sm-7 px-0'>${orderDetail.serviceName} </div> <div class='col-sm-5 px-0'>- ${orderDetail.totalPage} Trang</div></div>
              <div class='row mx-0'><div class='col-sm-7 px-0'>- ${orderDetail.catalogueProductName}</div> <div class='col-sm-5 px-0'>- ${orderDetail.catalogueSizeName}</div></div>
              <div class='row mx-0'><div class='col-sm-7 px-0'>- ${orderDetail.polymeCoverTypeName}</div></div></div>`;
    }

    if (
      orderDetail.processingSettingModels &&
      orderDetail.processingSettingModels.length > 0
    ) {
      let productNameCell = `<div><div class='row mx-0'><div class='col-sm-6 px-0'>${productName}</div>`;

      orderDetail.processingSettingModels?.forEach((item) => {
        productNameCell += `<div class='col-sm-6 px-0'>- ${
          item?.processingSettingModelItems
            ? item.processingSettingModelItems[0].name
            : ''
        } </div>`;
      });
      return productNameCell + '</div>';
    }

    return `<div>
    <div class='row mx-0'>
    <div class='col-sm-6 px-0'>
      ${productName}
    </div>
      <div class='col-sm-6 px-0'>
    ${
      orderDetail.polymeCoverTypeName
        ? '- ' + orderDetail.polymeCoverTypeName
        : ''
    }</div>
    <div class='col-sm-6 px-0'>
    ${
      orderDetail.processingTypeName
        ? '- ' + orderDetail.processingTypeName
        : ''
    }</div></div>
    ${
      orderDetail.processingTypeName2
        ? '- ' + orderDetail.processingTypeName2
        : ''
    }</div>`;
  }

  isDisableStatusOption(orderDetail: OrderDetailViewModel, status) {
    const currentStatusFlow = OrderDetailStatusChangeFlow.find(
      (s) => s.status == orderDetail.status
    );
    if (currentStatusFlow) {
      return currentStatusFlow.nextValue != status;
    }
    return true;
  }
}
