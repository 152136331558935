import { getDispatch } from '../get-dispatch';
import { DispatchAction } from '../utility/dispatch-action';
import {
  ExpensesActions,
  EXPENSES_STATE_NAMESPACE
} from './expenses.module-types';

export const dispatchExpensesAction: DispatchAction<ExpensesActions> = getDispatch(
  EXPENSES_STATE_NAMESPACE
);
