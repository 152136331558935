import { Vue, Component, Watch } from 'vue-property-decorator';
import BCardCode from '@core/components/b-card-code/BCardCode.vue';
import {
  BAvatar,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BRow,
  BCol,
  BDropdown,
  BDropdownItem,
  BCard,
  BCardHeader,
  BCardBody,
  BButton,
  BForm,
  BCardText,
  BImg,
  BBadge,
  BFormTextarea,
  BFormFile,
  BLink,
  BFormCheckbox,
  BFormCheckboxGroup,
  BInputGroup,
  BInputGroupAppend,
  BInputGroupText,
  BModal,
  BOverlay
} from 'bootstrap-vue';
import {
  OrderNamespace,
  OrderGetter,
  OrderAction,
  ORDER_STATE_NAMESPACE,
  OrderMutation,
  OrderState
} from '@/store/order/order.module-types';
import {
  OrderViewModel,
  OrderStatus,
  CutType,
  OrderDeliveryType,
  OrderDetailType,
  OrderFormularType,
  CustomerLevel,
  Gender,
  OrderDetailViewModel,
  MasterDataViewModel,
  CreateOrderDetailInput,
  ExpensesViewModel
} from '@/api/api';
import {
  convertCurrencyToNumber,
  enumToDropdownOptions,
  formatCurrency,
  formatDate,
  formatThousand
} from '@/utility/utils';
import { DropdownOption } from '@/utility/dropdowns/dropdownOptions';
import { quillEditor } from 'vue-quill-editor';
import { VueGoodTable } from 'vue-good-table';
import { mapActions, mapMutations, mapState } from 'vuex';
import { COMPANY_INVOICE_INFO } from '@/utility/constants';
import { PrintType } from '@/views/print/orderDetailPrint/OrderDetailPrint';
import AuthStorageService from '@/utility/account/auth.storage.service';
import {
  SETTING_STATE_NAMESPACE,
  SettingAction,
  SettingState
} from '@/store/setting/setting.module-types';
import {
  ExpensesGetter,
  ExpensesNamespace
} from '@/store/expenses/expenses.module-types';
import VueGallerySlideshow from 'vue-gallery-slideshow';

@Component({
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BCardCode,
    BAvatar,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BRow,
    BCol,
    BDropdown,
    BDropdownItem,
    BButton,
    BForm,
    BCardText,
    BImg,
    BBadge,
    BFormTextarea,
    BFormFile,
    BLink,
    quillEditor,
    BFormCheckbox,
    BFormCheckboxGroup,
    VueGoodTable,
    BInputGroup,
    BInputGroupAppend,
    BInputGroupText,
    BModal,
    BOverlay,
    VueGallerySlideshow
  },
  computed: {
    ...mapState(SETTING_STATE_NAMESPACE, [SettingState.masterData]),
    ...mapState(ORDER_STATE_NAMESPACE, [OrderState.companyInvoiceInfo])
  },
  methods: {
    ...mapActions(SETTING_STATE_NAMESPACE, [SettingAction.fetchMasterData]),
    ...mapMutations(ORDER_STATE_NAMESPACE, [
      OrderMutation.setCompanyInvoiceInfo
    ])
  }
})
export default class ExpensesDetailModal extends Vue {
  @ExpensesNamespace.Getter(ExpensesGetter.expenses)
  expenses!: ExpensesViewModel;

  companyInvoiceInfo!: {
    companyName: string;
    companyAddress: string;
    companyPhone: string;
  };

  orderId = '';
  VUE_APP_API_BASE_HOST = process.env.VUE_APP_API_BASE_HOST;
  URL = URL;
  OrderDetailType = OrderDetailType;
  orderStatusOptions: DropdownOption[] = enumToDropdownOptions(OrderStatus);
  cutTypeOptions: DropdownOption[] = enumToDropdownOptions(CutType);
  OrderDeliveryType = OrderDeliveryType;
  CustomerLevel = CustomerLevel;
  OrderFormularType = OrderFormularType;
  formatCurrency = formatCurrency;
  formatThousand = formatThousand;
  formatDate = formatDate;
  companyInvoiceInfoModel: {
    companyName: string;
    companyAddress: string;
    companyPhone: string;
  } | null = null;
  editingFields = {
    companyName: false,
    companyAddress: false,
    companyPhone: false
  };
  isLoading = true;

  masterData!: MasterDataViewModel;
  fetchMasterData!: () => Promise<void>;

  hasFullPermission = AuthStorageService.isFullPermission();

  setCompanyInvoiceInfo!: (data) => void;

  get totalPrice(): number {
    let price = 0;
    this.expenses?.expensesDetails?.forEach((s) => (price += s.total));
    const total =
      convertCurrencyToNumber(this.expenses.shippingFee) +
      (convertCurrencyToNumber(this.expenses.vatAmount) / 100) * price +
      price -
      convertCurrencyToNumber(this.expenses.couponAmount);
    return total < 0 ? 0 : total;
  }

  get remainAmount(): number {
    return (
      this.totalPrice - convertCurrencyToNumber(this.expenses.depositAmount)
    );
  }

  get colSpanNumber(): number {
    return 6;
  }

  get imagePaths() {
    if (this.expenses) {
      return this.expenses.expensesDetails?.flatMap((s) => {
        return s.imagePaths?.map((s) => `${this.VUE_APP_API_BASE_HOST}/${s}`);
      });
    }
    return [];
  }

  index = null;

  mounted() {
    if (!this.masterData) {
      this.fetchMasterData();
    }

    this.companyInvoiceInfoModel = {
      companyAddress: this.companyInvoiceInfo.companyAddress,
      companyName: this.companyInvoiceInfo.companyName,
      companyPhone: this.companyInvoiceInfo.companyPhone
    };

    this.resetCompanyInvoiceInfoModel();
    localStorage.setItem(
      COMPANY_INVOICE_INFO,
      JSON.stringify(this.companyInvoiceInfo)
    );
  }

  resetCompanyInvoiceInfoModel() {
    this.companyInvoiceInfoModel = {
      companyAddress: this.companyInvoiceInfo.companyAddress,
      companyName: this.companyInvoiceInfo.companyName,
      companyPhone: this.companyInvoiceInfo.companyPhone
    };
  }

  onPrint() {
    const newWindow = window.open(
      `/print-order/${
        this.expenses.id
      }?type=${PrintType.OrderDetail.toString()}`,
      '_blank',
      `width=1000,height=${window.innerHeight}`
    );
    if (newWindow === null) return;
  }

  changeCompanyInfo() {
    this.setCompanyInvoiceInfo({
      companyAddress: this.companyInvoiceInfoModel?.companyAddress ?? '',
      companyName: this.companyInvoiceInfoModel?.companyName ?? '',
      companyPhone: this.companyInvoiceInfoModel?.companyPhone ?? ''
    });

    localStorage.setItem(
      COMPANY_INVOICE_INFO,
      JSON.stringify(this.companyInvoiceInfo)
    );

    this.resetCompanyInvoiceInfoModel();
    this.editingFields = {
      companyName: false,
      companyAddress: false,
      companyPhone: false
    };
  }

  // getServiceName(orderDetail: OrderDetailViewModel) {
  //   const productName = orderDetail.serviceName;
  //   if (
  //     orderDetail.categoryId &&
  //     orderDetail.categoryId === this.masterData.catalogueCategoryId
  //   ) {
  //     return `<div><div class='row mx-0'><div class='col-sm-7 px-0'>${orderDetail.serviceName} </div> <div class='col-sm-5 px-0'>- ${orderDetail.totalPage} Trang</div></div>
  //             <div class='row mx-0'><div class='col-sm-7 px-0'>- ${orderDetail.catalogueProductName}</div> <div class='col-sm-5 px-0'>- ${orderDetail.catalogueSizeName}</div></div>
  //             <div class='row mx-0'><div class='col-sm-7 px-0'>- ${orderDetail.polymeCoverTypeName}</div></div></div>`;
  //   }

  //   if (
  //     orderDetail.processingSettingModels &&
  //     orderDetail.processingSettingModels.length > 0
  //   ) {
  //     let productNameCell = `<div><div class='row mx-0'><div class='col-sm-6 px-0'>${productName}</div>`;

  //     orderDetail.processingSettingModels?.forEach((item) => {
  //       productNameCell += `<div class='col-sm-6 px-0'>- ${
  //         item?.processingSettingModelItems
  //           ? item.processingSettingModelItems[0].name
  //           : ''
  //       } </div>`;
  //     });
  //     return productNameCell + '</div>';
  //   }

  //   return `<div>
  //   <div class='row mx-0'>
  //   <div class='col-sm-6 px-0'>
  //     ${productName}
  //   </div>
  //     <div class='col-sm-6 px-0'>
  //   ${
  //     orderDetail.polymeCoverTypeName
  //       ? '- ' + orderDetail.polymeCoverTypeName
  //       : ''
  //   }</div>
  //   <div class='col-sm-6 px-0'>
  //   ${
  //     orderDetail.processingTypeName
  //       ? '- ' + orderDetail.processingTypeName
  //       : ''
  //   }</div></div>
  //   ${
  //     orderDetail.processingTypeName2
  //       ? '- ' + orderDetail.processingTypeName2
  //       : ''
  //   }</div>`;
  //}

  @Watch('expenses')
  updateLoading() {
    if (this.expenses) {
      this.isLoading = false;
    }
  }

  getCompanyAddress() {
    const address = this.companyInvoiceInfo.companyAddress;
    return this.truncateAddress(address);
  }

  truncateAddress(value) {
    return value.replace(
      /Địa chỉ 1|Địa chỉ 2|Văn phòng|Cơ sở 1:|Xưởng in:|: /gi,
      ''
    );
  }
}
