


















































import { UpdateCODNoteInput } from '@/api/api';
import ApiClientFactory from '@/api/apiClientFactory';
import { Vue, Component, Prop } from 'vue-property-decorator';
import {
  BModal,
  BFormInput,
  BRow,
  BCol,
  BContainer,
  BFormGroup,
  BForm,
  BButton,
  BFormTextarea
} from 'bootstrap-vue';
import {
  ValidationProvider,
  ValidationObserver
} from 'vee-validate/dist/vee-validate.full';
import {
  DEFAULT_ORDER_PAGE_SIZE,
  DEFAULT_PAGE_SIZE
} from '@/utility/pagination/pagination';
import { DropdownOption } from '@/utility/dropdowns/dropdownOptions';
import { listVueMixin } from '@/utility/pagination/list-vue.mixin';
import { expensesListDispatcher } from '../store/expenses-list.module';
import { MODAL_IDS, PAYMENT_NOTE_TYPE } from '@/utility/constants';

@Component({
  components: {
    BModal,
    BFormInput,
    BContainer,
    BRow,
    BCol,
    BFormGroup,
    BForm,
    BButton,
    BFormTextarea,
    ValidationProvider,
    ValidationObserver
  },
  mixins: [
    listVueMixin({
      initialInput: {
        itemsPerPage: DEFAULT_ORDER_PAGE_SIZE,
        searchText: '',
        filterStatus: [] as DropdownOption[],
        filterPaymentStatus: [] as DropdownOption[],
        filterEmployee: ''
      },
      dispatcher: expensesListDispatcher
    })
  ]
})
export default class PaymentNoteModal extends Vue {
  @Prop({ type: Object, required: true })
  noteData!: {
    orderId: number;
    type: string;
    amount: number | null | undefined;
    note: string | null | undefined;
  };

  MODAL_IDS = MODAL_IDS;
  PAYMENT_NOTE_TYPE = PAYMENT_NOTE_TYPE;
  expensesListDispatcher = expensesListDispatcher;

  onOkClick(bvModalEvent) {
    bvModalEvent.preventDefault();
    this.onSubmit();
  }

  onSubmit() {
    switch (this.noteData.type) {
      case PAYMENT_NOTE_TYPE.COD:
        this.submitCODNote();
        break;
      case PAYMENT_NOTE_TYPE.BankTransfer:
        this.submitBankingNote();
        break;
      default:
        break;
    }
  }

  getTitle() {
    switch (this.noteData.type) {
      case PAYMENT_NOTE_TYPE.COD:
        return this.$t('COD');
      case PAYMENT_NOTE_TYPE.BankTransfer:
        return this.$t('BankingNote');
      default:
        return '';
    }
  }

  submitBankingNote() {
    const client = new ApiClientFactory().orderClient();

    client
      .updateBankingNote(this.noteData.orderId, this.noteData.note ?? '')
      .then((result) => {
        if (result) {
          this.expensesListDispatcher.load();
          this.$bvToast.toast('Cập nhật thành công', {
            title: `${this.$t('BankingNote')}`,
            toaster: 'b-toaster-bottom-right',
            variant: 'success'
          });
          this.$bvModal.hide(MODAL_IDS.PAYMENT_NOTE_MODAL);
          return;
        }

        this.$bvToast.toast('Cập nhật thất bại', {
          title: `${this.$t('BankingNote')}`,
          toaster: 'b-toaster-bottom-right',
          variant: 'danger'
        });
      })
      .catch((err) => {
        this.$bvToast.toast('Cập nhật thất bại', {
          title: `${this.$t('BankingNote')}`,
          toaster: 'b-toaster-bottom-right',
          variant: 'danger'
        });
      });
  }

  getCODNoteModal() {
    return {
      codAmount: this.noteData.amount ?? 0,
      note: this.noteData.note ?? ''
    } as UpdateCODNoteInput;
  }

  submitCODNote() {
    const clientApi = new ApiClientFactory();
    clientApi
      .orderClient()
      .updateCODNote(this.noteData.orderId, this.getCODNoteModal())
      .then((result) => {
        if (result) {
          this.expensesListDispatcher.load();
          this.$bvToast.toast('Cập nhật thành công', {
            title: `${this.$t('COD')}`,
            toaster: 'b-toaster-bottom-right',
            variant: 'success'
          });
          this.$bvModal.hide(MODAL_IDS.PAYMENT_NOTE_MODAL);
          return;
        }

        this.$bvToast.toast('Cập nhật thất bại', {
          title: `${this.$t('COD')}`,
          toaster: 'b-toaster-bottom-right',
          variant: 'danger'
        });
      })
      .catch((err) => {
        this.$bvToast.toast('Cập nhật thất bại', {
          title: `${this.$t('COD')}`,
          toaster: 'b-toaster-bottom-right',
          variant: 'danger'
        });
      });
  }

  onCancel() {
    this.$bvModal.hide(MODAL_IDS.PAYMENT_NOTE_MODAL);
  }
}
