import { Vue, Component, Watch } from 'vue-property-decorator';
import BCardCode from '@core/components/b-card-code/BCardCode.vue';
import {
  BAvatar,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BRow,
  BCol,
  BDropdown,
  BDropdownItem,
  BCard,
  BCardHeader,
  BCardBody,
  BButton,
  BModal,
  BForm,
  BCardText,
  BImg,
  BBadge,
  BTabs,
  BTab
} from 'bootstrap-vue';
import ExpensesList from '@/views/expenses/expensesList/ExpensesList.vue';
import { ListVue, listVueMixin } from '@/utility/pagination/list-vue.mixin';
import { expensesListDispatcher } from './expensesList/store/expenses-list.module';
import { DEFAULT_ORDER_PAGE_SIZE } from '@/utility/pagination/pagination';
import { DropdownOption } from '@/utility/dropdowns/dropdownOptions';
import ExpensesForm from '@/views/expenses/expensesForm/ExpensesForm.vue';
import ExpensesStastiticNavBar from './ExpensesStastiticNavBar.vue';
import { ExpensesListViewModel, OrderListViewModel } from '@/api/api';

@Component({
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BCardCode,
    BAvatar,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BRow,
    BCol,
    BDropdown,
    BDropdownItem,
    BButton,
    BModal,
    BForm,
    BCardText,
    BImg,
    BBadge,
    BTabs,
    BTab,
    ExpensesList,
    ExpensesForm,
    ExpensesStastiticNavBar
  },
  mixins: [
    listVueMixin({
      initialInput: {
        itemsPerPage: DEFAULT_ORDER_PAGE_SIZE,
        searchText: '',
        filterStatus: [] as DropdownOption[],
        filterPaymentStatus: [] as DropdownOption[],
        filterEmployee: ''
      },
      dispatcher: expensesListDispatcher
    })
  ]
})
export default class ExpensesPage extends Vue {
  expensesListDispatcher = expensesListDispatcher;
  selectedTab = 0;
  editExpensesId = 0;
  selectedExpensess: ExpensesListViewModel[] = [];
  previousTab = 0;

  $refs!: {
    expensesListRef: any;
    expensesBillListRef: any;
  };

  get isDisplayOrderNavbar() {
    return this.selectedExpensess.length > 0 && this.selectedTab == 0;
  }

  setSelectedExpensess(values: ExpensesListViewModel[]) {
    this.selectedExpensess = [...values];
  }

  resetTab() {
    window.scrollTo(0, 0);
    this.selectedTab = this.previousTab;
    this.editExpensesId = 0;
    this.expensesListDispatcher.load();
  }

  handleEditExpenses(id) {
    this.previousTab = this.selectedTab;
    this.editExpensesId = id;
    this.selectedTab = 1;
  }

  onUpdateStatusSuccess() {
    this.$refs.expensesListRef.reloadList(
      this.selectedExpensess.map((s) => s.id)
    );
    this.$refs.expensesBillListRef.reloadList(
      this.selectedExpensess.map((s) => s.id)
    );
  }
}
