import {
  CustomerLevel,
  CustomerViewModel,
  ExpensesDetailViewModel,
  ExpensesListViewModel,
  ExpensesPaymentStatus,
  MasterDataViewModel,
  OrderDetailStatus,
  OrderPaymentMethod,
  OrderStatistics,
  SelectModelOfString,
  UpdateExpensesPaymentStatusInput,
  UpdateOrderPaymentMethodInput
} from './../../../api/api';
import { Vue, Component, Watch, Prop, Emit } from 'vue-property-decorator';
import BCardCode from '@core/components/b-card-code/BCardCode.vue';
import {
  BContainer,
  BAvatar,
  BPagination,
  BFormGroup,
  BInputGroup,
  BFormInput,
  BFormSelect,
  BRow,
  BCol,
  BDropdown,
  BDropdownItem,
  BCard,
  BCardHeader,
  BCardBody,
  BButton,
  BModal,
  BForm,
  BCardText,
  BImg,
  BBadge,
  BLink,
  BFormDatepicker,
  BTooltip,
  BInputGroupAppend,
  BInputGroupText,
  BDropdownForm,
  BFormTextarea,
  BOverlay,
  VBTooltip
} from 'bootstrap-vue';
import { VueGoodTable } from 'vue-good-table';
import { ListVue, listVueMixin } from '@/utility/pagination/list-vue.mixin';
import Pagination from '@/@core/components/paginations/Pagination.vue';
import {
  OrderListViewModel,
  OrderPaymentStatus,
  UpdateOrderPaymentStatusInput,
  UpdateOrderStatusInput,
  OrderStatus,
  DownloadOrderReportInput,
  EmployeeListViewModel,
  OrderDetailViewModel
} from '@/api/api';
import {
  expensesListDispatcher,
  ExpensesListInput,
  expensesListNamespace
} from './store/expenses-list.module';
import {
  ListGetter,
  ListGetterTypes
} from '@/utility/pagination/get-list.module-type';
import {
  DEFAULT_ORDER_PAGE_SIZE,
  DEFAULT_PAGE_SIZE,
  DEFAULT_PAGE_SIZE_OPTIONS,
  getItemsPerPage,
  getValueOrUndefined
} from '@/utility/pagination/pagination';
import { DropdownOption } from '@/utility/dropdowns/dropdownOptions';
import {
  enumToDropdownOptions,
  StringFormat,
  formatCurrency,
  formatDate,
  convertCurrencyToNumber
} from '@/utility/utils';
import router from '@/router';
import vSelect from 'vue-select';
import ApiClientFactory from '@/api/apiClientFactory';
import { mapActions, mapState } from 'vuex';
import {
  EMPLOYEE_STATE_NAMESPACE,
  EmployeeState,
  EmployeeAction
} from '@/store/employee/employee.module-types';
import OrderItemDetailsModal from './modals/OrderItemDetailsModal.vue';
import ExpensesDetailModal from '../expensesDetail/ExpensesDetailModal.vue';
import { isThisSecond } from 'date-fns';
import {
  CustomerAction,
  CustomerGetter,
  CustomerState,
  CUSTOMER_STATE_NAMESPACE
} from '@/store/customer/customer.module-types';
import { PriceNamespace } from '@/store/price/price.module-types';
import { ExpensesAction } from '@/store/expenses/expenses.module-types';
import { dispatchExpensesAction } from '@/store/expenses/expenses.dispatch';
import { MODAL_IDS, PAYMENT_NOTE_TYPE } from '@/utility/constants';
import PaymentNoteModal from './modals/PaymentNoteModal.vue';
import ProducingStatusSumary from '../../producing/ProducingStatusSumary.vue';
import {
  SettingAction,
  SettingState,
  SETTING_STATE_NAMESPACE
} from '@/store/setting/setting.module-types';
import AuthStorageService from '@/utility/account/auth.storage.service';
import { PrintType } from '@/views/print/orderDetailPrint/OrderDetailPrint';
import DepositInputModal from './modals/DepositInputModal.vue';
@Component({
  components: {
    BContainer,
    BCard,
    BCardHeader,
    BCardBody,
    BCardCode,
    VueGoodTable,
    BAvatar,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BRow,
    BCol,
    BDropdown,
    BDropdownItem,
    BButton,
    BModal,
    BForm,
    BCardText,
    Pagination,
    BImg,
    BBadge,
    BLink,
    vSelect,
    BFormDatepicker,
    BTooltip,
    BInputGroup,
    BInputGroupAppend,
    BInputGroupText,
    BDropdownForm,
    BFormTextarea,
    OrderItemDetailsModal,
    ExpensesDetailModal,
    BOverlay,
    PaymentNoteModal,
    ProducingStatusSumary,
    DepositInputModal
  },
  mixins: [
    listVueMixin({
      initialInput: {
        itemsPerPage: DEFAULT_ORDER_PAGE_SIZE,
        searchText: '',
        filterStatus: [] as DropdownOption[],
        filterPaymentStatus: [] as DropdownOption[],
        filterEmployee: '',
        isExportBilling: undefined
      },
      dispatcher: expensesListDispatcher,
      debouncedWatchers: [
        (listInput) => listInput.searchText,
        (listInput) => listInput.filterEmployee
      ]
    })
  ],
  computed: {
    ...mapState(CUSTOMER_STATE_NAMESPACE, [CustomerState.customers]),
    ...mapState(EMPLOYEE_STATE_NAMESPACE, [EmployeeState.saleEmployees]),
    ...mapState(SETTING_STATE_NAMESPACE, [SettingState.masterData])
  },
  methods: {
    ...mapActions(EMPLOYEE_STATE_NAMESPACE, [
      EmployeeAction.fetchSaleEmployees
    ]),
    ...mapActions(CUSTOMER_STATE_NAMESPACE, [CustomerAction.fetchCustomers]),
    ...mapActions(SETTING_STATE_NAMESPACE, [SettingAction.fetchMasterData])
  }
})
export default class ExpensesList
  extends Vue
  implements ListVue<ExpensesListViewModel, ExpensesListInput> {
  @expensesListNamespace.Getter(ListGetter.state)
  readonly listState!: ListGetterTypes<
    ExpensesListViewModel,
    ExpensesListInput
  >[ListGetter.state];

  customers!: CustomerViewModel[];
  fetchCustomers!: () => Promise<void>;
  masterData!: MasterDataViewModel;
  fetchMasterData!: () => Promise<void>;
  saleEmployees!: SelectModelOfString[];
  fetchSaleEmployees!: () => Promise<void>;

  $refs!: {
    paymentMethodRef: any;
    paymentMethodFormRef: any;
    bankingNoteRef: any;
    depositInputModalRef: any;
  };

  expensesListDispatcher = expensesListDispatcher;
  VUE_APP_API_BASE_HOST = process.env.VUE_APP_API_BASE_HOST;
  ORDER_STATUS_ENUM = OrderStatus;
  EXPENSES_PAYMENT_STATUS_ENUM = ExpensesPaymentStatus;
  formatCurrency = formatCurrency;
  formatDate = formatDate;
  MODAL_IDS = MODAL_IDS;
  ORDER_PAYMENT_STATUS_PREFIX = 'OrderPaymentStatus';
  ORDER_STATUS_PREFIX = 'OrderStatus';

  hasFullPermission = AuthStorageService.isFullPermission();

  isLoading = false;
  expensesSelectedRows: ExpensesListViewModel[] = [];
  // expensesStatusOptionFilters: DropdownOption[] = enumToDropdownOptions(
  //   OrderStatusFilter
  // ).map((s) => {
  //   return {
  //     value: `${this.ORDER_STATUS_PREFIX}-${s.value}`,
  //     text: s.text
  //   };
  // });
  expensesPaymentStatusOptionFilters: DropdownOption[] = enumToDropdownOptions(
    ExpensesPaymentStatus
  ).map((s) => {
    return {
      value: `${this.ORDER_PAYMENT_STATUS_PREFIX}-${s.value}`,
      text: s.text
    };
  });
  filterOptionButtons: {
    value: DropdownOption;
    isSelecting: boolean;
    count: number;
  }[] = [];
  expensesPaymentMethodOptionFilters: DropdownOption[] = enumToDropdownOptions(
    OrderPaymentMethod
  ).map((s) => {
    return {
      value: s.value,
      text: s.text
    };
  });

  expensesStatusOptions: DropdownOption[] = enumToDropdownOptions(OrderStatus);
  expensesPaymentStatusOptions: DropdownOption[] = enumToDropdownOptions(
    OrderPaymentStatus
  );

  get pageSizeOptions() {
    return DEFAULT_PAGE_SIZE_OPTIONS;
  }

  get employeeOptions(): DropdownOption[] {
    let result: DropdownOption[] = [];
    result = this.saleEmployees?.map(
      (x) =>
        ({
          value: x.id,
          text: x.name
        } as DropdownOption)
    );

    result.unshift({
      text: 'Tất cả',
      value: ''
    });
    return result;
  }

  totalCustomerPay = 0;
  remainTotalCustomerPay = 0;
  isDownloadingReport = false;
  paymentMethodInput = 0;
  bankingNoteInput = '';
  noteData: {
    orderId: number;
    type: string;
    amount: number | null | undefined;
    note: string | null | undefined;
  } = {
    orderId: 0,
    type: PAYMENT_NOTE_TYPE.COD,
    amount: 0,
    note: ''
  };
  expensesActionOpened = false;

  header = [
    {
      label: this.$t('Order'),
      field: 'customerName',
      width: '20%',
      thClass: 'order-header-style ml-1 font-weight-500'
    },
    {
      label: this.$t('TotalPrice'),
      field: 'totalCustomerPay',
      thClass: 'order-header-style'
    },
    {
      label: this.$t('RemainAmount'),
      field: 'remainTotalCustomerPay',
      thClass: 'order-header-style',
      tdClass: 'no-wrap'
    },
    {
      label: this.$t('BankingNote'),
      field: 'bankingNote',
      thClass: 'order-header-style'
    },
    // {
    //   label: this.$t('Tình trạng sản xuất'),
    //   field: 'producingStatus',
    //   thClass: 'order-header-style',
    //   width: '15%'
    // },
    // {
    //   label: this.$t('Status'),
    //   field: 'status',
    //   thClass: 'order-header-style'
    // },
    {
      label: this.$t('PaymentStatus'),
      field: 'paymentStatus',
      thClass: 'order-header-style'
    },
    // {
    //   label: 'NVKD',
    //   field: 'employeeName',
    //   thClass: 'order-header-style',
    //   width: '2%'
    // },
    {
      label: '',
      field: '_action',
      sortable: false,
      thClass: 'order-header-style w-fit-content',
      tdClass: 'vgt-right-align w-fit-content'
    }
  ];

  PAYMENT_NOTE_TYPE = PAYMENT_NOTE_TYPE;

  getTotalCustomerPay(expenses: ExpensesListViewModel) {
    const vatAmount =
      (convertCurrencyToNumber(expenses.vatAmount) / 100) *
      expenses.totalCustomerPay;
    const total =
      convertCurrencyToNumber(expenses.shippingFee) +
      vatAmount +
      expenses.totalCustomerPay -
      convertCurrencyToNumber(expenses.discountAmount);
    return total < 0 ? 0 : total;
  }

  trimText(text) {
    if (text) {
      return text.trim().substring(0, 50) + '…';
    }
    return '';
  }

  displayExpensesPaymentMethod(value) {
    return this.$t(OrderPaymentMethod[value]);
  }

  displayExpensesStatus(value) {
    return this.expensesStatusOptions.find((x) => x.value === value)
      ? this.$t(
          this.expensesStatusOptions.find((x) => x.value === value)
            ?.text as string
        )
      : '';
  }

  displayExpensesPaymentStatus(value) {
    return this.expensesPaymentStatusOptions.find((x) => x.value === value)
      ? this.$t(
          this.expensesPaymentStatusOptions.find((x) => x.value === value)
            ?.text as string
        )
      : '';
  }

  displayCustomerLevel(value) {
    const customerLevels = enumToDropdownOptions(CustomerLevel);
    return customerLevels.find((x) => x.value === value)
      ? this.$t(customerLevels.find((x) => x.value === value)?.text as string)
      : '';
  }

  displayEmployeeName(value: string) {
    const names = value.split(' ');
    return names.length === 1 ? value : names.map((n) => n[0]).join('');
  }

  mounted() {
    this.fetchSaleEmployees().then(() => {
      if (!this.customers || this.customers?.length === 0) {
        this.fetchCustomers();
      }

      if (!this.masterData) {
        this.fetchMasterData();
      }

      this.filterOptionButtons = this.initButtonSelected();
      this.listState.input.dateTo = new Date();
      this.listState.input.dateFrom = new Date();
      this.listState.input.dateFrom.setDate(
        this.listState.input.dateFrom.getDate() - 7
      );
      expensesListDispatcher.load();
    });
  }

  reload() {
    expensesListDispatcher.load();
  }

  view(id) {
    this.openExpensesDetailModal(id);
  }

  edit(id: number) {
    const expenses = this.listState.items.find((s) => s.id === id);
    if (
      !expenses ||
      !this.isExpensesAbleToUpdate(
        expenses.status,
        expenses.expensesDetails ?? []
      )
    ) {
      return;
    }

    this.$emit('OnEditExpenses', id);
  }

  remove(id: number) {
    if (this.hasFullPermission) {
      const client = new ApiClientFactory().expensesClient();
      client.delete(id).then(() => {
        expensesListDispatcher.load();
        // show toast
        this.$bvToast.toast('Xóa đơn hàng thành công', {
          title: 'Đơn hàng',
          toaster: 'b-toaster-bottom-right',
          variant: 'success'
        });
      });
    } else {
      this.$bvToast.toast('Bạn không có quyền xóa đơn hàng', {
        title: this.$t('Order').toString(),
        toaster: 'b-toaster-bottom-right',
        variant: 'danger'
      });
    }
  }

  rowStyleClassFn(row: OrderListViewModel) {
    return ExpensesPaymentStatus[row.paymentStatus];
  }

  // updateStatus(id: number, status: number) {
  //   if (AuthStorageService.isProducingPermission()) {
  //     this.$bvToast.toast('Bạn không có quyền đổi trạng thái', {
  //       title: this.$t('Order').toString(),
  //       toaster: 'b-toaster-bottom-right',
  //       variant: 'danger'
  //     });
  //     return;
  //   }
  //   const expenses = this.listState.items.find((s) => s.id === id);
  //   if (
  //     !expenses ||
  //     !this.isExpensesAbleToUpdate(
  //       expenses.status,
  //       expenses.expensesDetails ?? []
  //     )
  //   ) {
  //     return;
  //   }

  //   const client = new ApiClientFactory().orderClient();

  //   const body = {
  //     ids: [id],
  //     status
  //   } as UpdateOrderStatusInput;

  //   client.updateStatus(body).then(() => {
  //     expensesListDispatcher.load();
  //     this.$bvToast.toast(
  //       StringFormat(
  //         this.$t('UpdateStatusSuccessfully').toString(),
  //         this.$t('Order').toString().toLowerCase()
  //       ),
  //       {
  //         title: this.$t('Order').toString(),
  //         toaster: 'b-toaster-bottom-right',
  //         variant: 'success'
  //       }
  //     );
  //   });
  // }

  async updatePaymentStatus(id: number, paymentStatus: number) {
    if (AuthStorageService.isProducingPermission()) {
      this.$bvToast.toast('Bạn không có quyền đổi trạng thái', {
        title: this.$t('Order').toString(),
        toaster: 'b-toaster-bottom-right',
        variant: 'danger'
      });
      return;
    }

    const expenses = this.listState.items.find((s) => s.id === id);
    if (
      !expenses ||
      !this.isExpensesAbleToUpdate(
        expenses.status,
        expenses.expensesDetails ?? []
      )
    ) {
      return;
    }

    let depositAmount = 0;
    const client = new ApiClientFactory().expensesClient();
    if (paymentStatus == ExpensesPaymentStatus.PaidDeposit) {
      // const depositAmountResult = await this.$swal({
      //   title: 'Nhập tiền đặt cọc',
      //   input: 'number',
      //   inputPlaceholder: 'Tiền cọc',
      //   confirmButtonText: this.$t('Save').toString(),
      //   showCloseButton: true
      // });

      // if (
      //   depositAmountResult.dismiss ||
      //   depositAmountResult.value === '' ||
      //   depositAmountResult.value <= 0
      // ) {
      //   return;
      // }

      // depositAmount = depositAmountResult.value;
      const result = await this.$refs.depositInputModalRef.show();
      if (isNaN(convertCurrencyToNumber(result))) {
        this.$bvToast.toast('Giá trị tiền cọc không hợp lệ.', {
          title: this.$t('Order').toString(),
          toaster: 'b-toaster-bottom-right',
          variant: 'danger'
        });
        return;
      }
      depositAmount = convertCurrencyToNumber(result);
    }

    depositAmount = depositAmount ?? 0;
    const body = {
      ids: [id],
      paymentStatus,
      depositAmount
    } as UpdateExpensesPaymentStatusInput;

    client.updatePaymentStatus(body).then(() => {
      expensesListDispatcher.load();
      this.$bvToast.toast(
        this.$t('UpdatePaymentStatusSuccessfully').toString(),
        {
          title: this.$t('Order').toString(),
          toaster: 'b-toaster-bottom-right',
          variant: 'success'
        }
      );
    });
  }

  selectionChanged(params: { selectedRows: ExpensesListViewModel[] }) {
    this.expensesSelectedRows = params.selectedRows;
    this.$emit('onSelectExpenses', params.selectedRows);
  }

  downloadReport() {
    let from, to;
    if (this.listState.input.dateFrom) {
      from = new Date(
        this.listState.input.dateFrom.getFullYear(),
        this.listState.input.dateFrom.getMonth(),
        this.listState.input.dateFrom.getDate(),
        0,
        0,
        0
      );
    }

    if (this.listState.input.dateTo) {
      // input.dateTo.setDate(input.dateTo.getDate() + 1);
      to = new Date(
        this.listState.input.dateTo.getFullYear(),
        this.listState.input.dateTo.getMonth(),
        this.listState.input.dateTo.getDate(),
        0,
        0,
        0
      );
      to.setDate(to.getDate() + 1);
    }

    const client = new ApiClientFactory().orderClient();
    this.isDownloadingReport = true;
    client
      .report(
        new DownloadOrderReportInput({
          dateFrom: from ? from.getTime() : null,
          dateTo: to ? to.getTime() : null,
          filterStatus: getValueOrUndefined(
            this.listState.input.filterStatus?.map((_) => _.value)
          ),
          filterPaymentStatus: getValueOrUndefined(
            this.listState.input.filterPaymentStatus?.map((_) => _.value)
          ),
          orderIds: this.expensesSelectedRows.map((s) => s.id)
        })
      )
      .then((response) => {
        this.isDownloadingReport = false;
        const url = window.URL.createObjectURL(
          response?.data as Blob | MediaSource
        );
        const a = document.createElement('a');
        a.href = url;
        a.download = 'report.xlsx';
        a.click();
      });
  }

  onFilterButton(selectedItem: DropdownOption): void {
    this.filterOptionButtons = this.filterOptionButtons.map((item) => {
      return { ...item, isSelecting: false };
    });

    const selectedValues = (selectedItem.value as string).split('-');
    const selectedValueIndex = this.filterOptionButtons.findIndex(
      (s) => s.value.value === selectedItem.value
    );

    const exsitingStatusIndex =
      selectedValues[0] === 'OrderStatus'
        ? this.listState.input.filterStatus?.findIndex(
            (s) => s.value === selectedValues[1]
          ) ?? -1
        : this.listState.input.filterPaymentStatus?.findIndex(
            (s) => s.value === selectedValues[1]
          ) ?? -1;

    this.listState.input.filterPaymentStatus = [];
    this.listState.input.filterStatus = [];

    if (exsitingStatusIndex === -1) {
      if (selectedValues[0] === 'OrderStatus') {
        this.listState.input.filterStatus?.push({
          value: selectedValues[1],
          text: selectedItem.text
        });
      } else {
        this.listState.input.filterPaymentStatus?.push({
          value: selectedValues[1],
          text: selectedItem.text
        });
      }

      this.filterOptionButtons[selectedValueIndex].isSelecting = true;
    }

    expensesListDispatcher.load();
  }

  initButtonSelected() {
    return this.expensesPaymentStatusOptionFilters.map((val) => {
      return {
        value: val,
        isSelecting: false,
        count: 0
      };
    });
  }

  onEmployeeFilterChange(value) {
    this.listState.input.filterEmployee = value?.value ?? '';
  }

  onChangePaymentMethod(id: string) {
    if (AuthStorageService.isProducingPermission()) {
      this.$bvToast.toast('Bạn không có quyền đổi trạng thái', {
        title: this.$t('Order').toString(),
        toaster: 'b-toaster-bottom-right',
        variant: 'danger'
      });
      return;
    }

    const updatePaymentMethodInput: UpdateOrderPaymentMethodInput = {
      orderIds: [Number.parseInt(id)],
      paymentMethod: this.paymentMethodInput
    } as UpdateOrderPaymentMethodInput;

    const client = new ApiClientFactory().expensesClient();
    client
      .updateOrderPaymentMethod(updatePaymentMethodInput)
      .then(() => {
        expensesListDispatcher.load();
        this.$bvToast.toast(
          StringFormat(
            this.$t('UpdatePaymentMethodSuccessfully').toString(),
            this.$t('Order').toString().toLowerCase()
          ),
          {
            title: this.$t('Order').toString(),
            toaster: 'b-toaster-bottom-right',
            variant: 'success',
            appendToast: true
          }
        );
      })
      .catch((err) => {
        this.$bvToast.toast(err, {
          title: this.$t('Order').toString(),
          toaster: 'b-toaster-bottom-right',
          variant: 'error',
          appendToast: true
        });
      });
  }

  onShowPaymentMethod(value) {
    this.paymentMethodInput = value;
  }

  async getExpensesDetail(id: number, customerName: string) {
    this.isLoading = true;
    dispatchExpensesAction(ExpensesAction.loadExpenses, id);
    this.isLoading = false;
    this.$bvModal.show(MODAL_IDS.EXPENSES_DETAIL_MODAL);
  }

  async openExpensesDetailModal(id) {
    this.isLoading = true;
    dispatchExpensesAction(ExpensesAction.loadExpenses, id);
    this.isLoading = false;
    this.$bvModal.show(MODAL_IDS.EXPENSES_DETAIL_MODAL);
  }

  // onCODNoteClick(expensesId) {
  //   const selectedExpenses = this.listState.items.find(
  //     (s) => s.id === expensesId
  //   );
  //   if (selectedExpenses) {
  //     this.noteData = {
  //       orderId: expensesId,
  //       type: PAYMENT_NOTE_TYPE.COD,
  //       amount: selectedExpenses.codAmount ?? 0,
  //       note: selectedExpenses.codNote ?? ''
  //     };

  //     this.$bvModal.show(MODAL_IDS.PAYMENT_NOTE_MODAL);
  //   }
  // }

  onBankTransferNoteClick(value, orderId) {
    if (orderId) {
      this.noteData = {
        orderId: orderId,
        type: PAYMENT_NOTE_TYPE.BankTransfer,
        amount: null,
        note: value ?? ''
      };

      this.$bvModal.show(MODAL_IDS.PAYMENT_NOTE_MODAL);
    }
  }

  async reloadList(selectedIds: number[]) {
    await expensesListDispatcher.load();
    selectedIds.forEach((element) => {
      const index = this.listState.items.findIndex((s) => s.id === element);
      if (index !== -1 && !this.listState.items[index]['vgtSelected'])
        this.$set(this.listState.items[index], 'vgtSelected', true);
    });

    this.$nextTick(() => {
      selectedIds.forEach((obj) => {
        // loop over each invitee
        const row = document.getElementById('row-id-' + obj); // find row that matches our invited invitee
        if (row) {
          // simple guard to ensure it's there (it should be but whatever)
          row.parentElement?.parentElement?.classList.add('selected');
        }
      });
    });
  }

  updateExpensesActionState(value, id) {
    const element = document.getElementsByClassName(
      'text-body align-right align-self-center ' + id
    );
    if (element) {
      if (value) {
        element[0]?.classList?.add('rotate');
      } else {
        element[0]?.classList?.remove('rotate');
      }
    }
    this.expensesActionOpened = value;
  }

  isExpensesAbleToUpdate(
    expensesStatus: OrderStatus,
    expensesDetails: ExpensesDetailViewModel[]
  ) {
    if (AuthStorageService.isProducingPermission()) {
      this.$bvToast.toast('Bạn không có quyền sửa đơn hàng', {
        title: this.$t('Order').toString(),
        toaster: 'b-toaster-bottom-right',
        variant: 'danger'
      });
      return false;
    }

    const inValidToUpdate =
      expensesStatus === OrderStatus.Delivered &&
      expensesDetails.findIndex(
        (s) => s.status !== OrderDetailStatus.Delivered
      ) === -1;
    if (inValidToUpdate && !this.hasFullPermission) {
      this.$bvToast.toast('Không thể chỉnh sửa đơn hàng đã hoàn thành', {
        title: this.$t('Order').toString(),
        toaster: 'b-toaster-bottom-right',
        variant: 'danger'
      });
    }

    return !inValidToUpdate || this.hasFullPermission;
  }

  // onPrintBill(orderId) {
  //   const newWindow = window.open(
  //     `/print-order/${orderId}?type=${PrintType.OrderDetail.toString()}`,
  //     '_blank',
  //     `width=1000,height=${window.innerHeight}`
  //   );
  //   if (newWindow === null) return;
  // }
}

// enum OrderStatusFilter {
//   Ordered = 1,
//   OrderReady = 4,
//   Delivered = 5,
//   Canceled = 6,
//   InStock = 14,
//   //Billing = 15,
//   ExpressDelivery = 12,

//   Done = 90,
//   NotDone = 91
// }
